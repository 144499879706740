import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../constants";
import { Loading } from "../components/Loading";
import { NoMatch } from "../components/NoMatch";
import './StaticPage.css';

export const StaticPage = () => {
  const params = useParams<{ language: string; page: string }>();

  const [loading, setLoading] = useState(true);
  const [pageItem, setPageItem] = useState({
    title: '',
    content: ''
  })

  useEffect(() => {
    const page = params.page ?? "";
    if (page) {
      setLoading(true);
      const language = params.language ?? 'en';
      fetch(`${API_URL}/admin/agreement/public/${page}/${language}`,).then(async response => {
        const result = await response.json();
        setPageItem({
          title: result?.content?.title,
          content: result?.content?.content,
        })
      }).finally(() => {
        setLoading(false);
      })
    }
  }, [params.page, params.language]);

  if (loading) {
    return <Loading/>;
  }

  return (
    <section className="static-page">
      {pageItem?.title && <h1>{pageItem.title}</h1>}
      {pageItem?.content && <article dangerouslySetInnerHTML={{ __html: pageItem.content }}/>}

      {!pageItem?.title && !pageItem?.content && (
        <NoMatch/>
      )}
    </section>
  )
};
